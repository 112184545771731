var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "textareaBox",
    staticClass: "textareaBox editablePageBody",
    class: _vm.isScroll ? "scroll" : "auto",
    attrs: { contenteditable: _vm.isContenteditable },
    domProps: { innerHTML: _vm._s(_vm.msg) },
    on: { input: _vm.getCurrentHeight }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }