<template>
  <div
    class="textareaBox editablePageBody"
    :class="isScroll ? 'scroll' : 'auto'"
    v-html="msg"
    :contenteditable="isContenteditable"
    ref="textareaBox"
    @input="getCurrentHeight"
  ></div>
  <!-- <iframe
      v-else
      id="editable_iframe"
      ref="editable_iframe"
      frameborder="0"
      :height="iframe_height"
    ></iframe> -->
</template>
<script>
import Tiptap from "../../components/common/Tiptap.vue";
import { Base64 } from "js-base64";
import { env } from "@/utils";
export default {
  data() {
    return {
      editor: null,
      msg: "",
      first: true,
      isScroll: true,
      isContenteditable: true,
      iframe_height: 0,
      calendar: null,
    };
  },
  components: {
    Tiptap,
  },
  computed: {
    langs() {
      const messages = this.$i18n.messages;
      let langs = [];
      for (const langName in messages) {
        const curmsg = messages[langName];
        let name = messages["zh-CN"].langs[langName];
        let item = {
          text: `${name}(${curmsg.langName})`,
          fulltext: [name, curmsg.langName],
          value: langName,
          code: curmsg.code,
          encode: curmsg.encode,
        };
        langs.push(item);
      }
      return langs;
    },
  },
  mounted() {
    window.deleteLargeAttachment = this.deleteLargeAttachment;
    this.getAPPDate();
    this.getLargeAttachmentInfo();
    this.getData();
  },
  beforeDestroy() {
    window.deleteLargeAttachment = null;
  },
  methods: {
    inputContent(html) {
      console.log(html);
    },
    getCidImageLink(params) {
      const paramString = `sectionId=${encodeURIComponent(
        params.sectionId
      )}&messageId=${encodeURIComponent(
        params.messageId
      )}&from=${encodeURIComponent(params.from)}`;
      return `${env("MAIL_BASEURL")}/mail/cid/download?${paramString}`;
    },
    handlerCidImageHtmlString(htmlString, currentItemInfo) {
      try {
        const imgSrcRegex = /<img.*?src=['"](.*?)['"]/g;
        const imageUrls = Array.from(
          htmlString.matchAll(imgSrcRegex),
          (match) => match[1]
        );
        const cidImageUrls = imageUrls.filter((url) => url.startsWith("cid:"));
        cidImageUrls.map((cid) => {
          const imageLink = this.getCidImageLink({
            sectionId: cid.split("cid:")[1],
            messageId: currentItemInfo.messageId,
            from: currentItemInfo.from,
          });
          htmlString = htmlString.replace(new RegExp(cid, "g"), imageLink);
        });
        htmlString = this.seImgStyle(htmlString);
      } catch (error) {}
      return htmlString;
    },
    seImgStyle(htmlString) {
      const htmlElement = document.createElement("div");
      htmlElement.innerHTML = htmlString;
      const imgTags = htmlElement.querySelectorAll("img");
      imgTags.forEach((img) => {
        const styleWidth = img.style.width;
        const hasWidthAttribute = img.hasAttribute("width");
        if (!hasWidthAttribute && !styleWidth) {
          img.style.width = "100%";
        }
      });
      const modifiedHtmlString = htmlElement.innerHTML;
      htmlElement.remove();
      return modifiedHtmlString;
    },
    isCompleteHTML(htmlString) {
      try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        return (
          doc &&
          doc.documentElement &&
          doc.documentElement.nodeName.toLowerCase() === "html"
        );
      } catch (e) {
        return false;
      }
    },
    extractBodyContent(htmlString) {
      if (this.isCompleteHTML(htmlString)) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        const bodyContent = doc.body.innerHTML;
        return bodyContent == "null" ? "" : bodyContent;
      } else {
        const bodyContentRegex = /<body[^>]*>([\s\S]*?)<\/body>/i;
        const matches = htmlString.match(bodyContentRegex);
        if (matches && matches.length >= 2) {
          return matches[1];
        } else {
          return htmlString;
        }
      }
    },
    // 获取大附件信息
    getLargeAttachmentInfo() {
      this.$bridge.registerHandler("largeAttachmentToJs", (data) => {
        const jsonInfo = JSON.parse(data);
        this.fillInLargeAttachment(jsonInfo);
      });
    },
    // 插入大附件
    fillInLargeAttachment(itemInfo) {
      setTimeout(() => {
        const largeAttachmentElement = document.getElementById(
          "emailLargeAttachment"
        );
        // 如果已经插入过大附件，则不在新创建
        if (largeAttachmentElement && largeAttachmentElement.innerHTML) {
          const attachmentHtml = this.createLargeAttachmentHtml(itemInfo);
          let handleArr = attachmentHtml.split("\n");
          handleArr = handleArr.map((e) =>
            e.replace(/^[\s]+/, "").replace(/[\s]+$/, "")
          );
          largeAttachmentElement.insertAdjacentHTML(
            "beforeend",
            handleArr.join("")
          );
        } else {
          const selector = document.getElementsByClassName("editablePageBody");
          const node = document.createElement("div");
          node.setAttribute(
            "style",
            "width:100%; min-height:0px; overflow:hidden;line-height: normal;position: relative;"
          );
          node.setAttribute("contenteditable", "false");
          node.id = "emailLargeAttachment";
          const titleHtml = `
            <div data-tag="largeAttachmentOriginal" style="width: 100%;height: 28px;text-align: center;position: relative;display: flex;align-items: center;justify-content: center;margin: 16px 0">
              <div style="position: absolute;top: 50%;left:0;right: 0;border-top: 1px dashed #D9D9D9;border-bottom: 1px dashed #D9D9D9;content:'';transform: translate3d(0, -50%, 0);"></div>
              <div style="position: relative;z-index: 1;background-color: white;color:#858585;font-size:14px">${this.$t(
                "largeAttachment"
              )}</div>
            </div>
          `;
          const attachmentHtml = this.createLargeAttachmentHtml(itemInfo);
          let handleArr = `${titleHtml}${attachmentHtml}`.split("\n");
          handleArr = handleArr.map((e) =>
            e.replace(/^[\s]+/, "").replace(/[\s]+$/, "")
          );
          node.innerHTML = handleArr.join("");
          if (selector[0].innerHTML == "") {
            this.msg = `<br/><br/><br/><br/>` + node.outerHTML;
          } else {
            this.msg = selector[0].innerHTML + "<br/>" + node.outerHTML;
          }
        }
      }, 0);
    },
    // 创建大附件HTML
    createLargeAttachmentHtml(itemInfo) {
      const htmlStr = `
        <a data-tag="large_attachment_item" download="${itemInfo.name}" href="${
        itemInfo.path
      }" target="_blank" style="max-width: calc(100% - 72px);position: relative;min-height: 50px;cursor:pointer;display: inline-flex;padding: 15px 20px; border: 1px solid #D9D9D9;background: #F5F5F5; align-items: center; justify-content: flex-start;border-radius: 4px;margin:0 0 10px 12px">
          <img style="display: block;width: 24px;height: 24px;margin-right: 4px;flex-shrink: 0;object-fit: cover" src=${this.getIconByFileName(
            itemInfo.name
          )} />
          <div style="font-size: 16px; line-height: 24px; color: #292d32; flex: 1; max-width: 240px; overflow: hidden;word-break: keep-all;">${
            itemInfo.name
          }</div>
          <div style="font-size: 14px; color: #858585; max-width: 120px; flex-shrink: 0; overflow: hidden;text-overflow: ellipsis;white-space: nowrap">(${this.changeLimit(
            itemInfo.size
          )})</div>
          <div style="position: absolute;width:14px;height:14px;border-radius:50%;background:rgba(0,0,0,0.5);top:-7px;right:-7px;display: flex;align-items: center; justify-content: center;color: white;font-size: 10px;cursor: pointer;" onclick='deleteLargeAttachment(event, this)'>X</div>
        </a>
      `;
      return htmlStr;
    },
    deleteLargeAttachment(event, element) {
      event.preventDefault();
      event.stopPropagation();
      element.parentNode.remove();
      const largeAttachmentElement = document.getElementById(
        "emailLargeAttachment"
      );
      const attachmentHtml = largeAttachmentElement.innerHTML;
      if (attachmentHtml.indexOf("large_attachment_item") == -1) {
        largeAttachmentElement.remove();
      }
    },
    changeLimit(limit) {
      let size = "";
      if (limit < 0.1 * 1024) {
        //小于0.1KB，则转化成B
        size = limit.toFixed(1) + "B";
      } else if (limit < 0.1 * 1024 * 1024) {
        //小于0.1MB，则转化成KB
        size = (limit / 1024).toFixed(1) + "KB";
      } else if (limit < 0.1 * 1024 * 1024 * 1024) {
        //小于0.1GB，则转化成MB
        size = (limit / (1024 * 1024)).toFixed(1) + "MB";
      } else {
        //其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(1) + "GB";
      }
      return size;
    },
    getIconByFileName(name) {
      const judgment = (text, type) => {
        const handlerText = text.toLowerCase();
        const handlerType = type.toLowerCase();
        let typeLength =
          handlerText.length - handlerText.lastIndexOf(handlerType);
        return (
          handlerText.lastIndexOf(handlerType) != -1 &&
          typeLength == handlerType.length
        );
      };
      if (judgment(name, "txt")) {
        return "https://imfile.melinked.com/2023/12/cc780240-9e75-4f4c-a0ae-f898c694bd5c.png?imageView2/0/interlace/1/format/png|imageslim";
      } else if (judgment(name, "doc") || judgment(name, "docx")) {
        return "https://imfile.melinked.com/2023/12/c64137f5-53e6-4347-9eec-6f7550824b0f.png?imageView2/0/interlace/1/format/png|imageslim";
      } else if (judgment(name, "pdf")) {
        return "https://imfile.melinked.com/2023/12/847cd3f1-85d7-4dce-9e61-150b1f4b79f7.png?imageView2/0/interlace/1/format/png|imageslim";
      } else if (judgment(name, "xlsx") || judgment(name, "xls")) {
        return "https://imfile.melinked.com/2023/12/a7e3d2bb-2ec1-43c3-818a-26ec0e7fdf6d.png?imageView2/0/interlace/1/format/png|imageslim";
      } else if (judgment(name, "pptx") || judgment(name, "ppt")) {
        return "https://imfile.melinked.com/2023/12/0918bba5-7d82-4ecf-8f11-098df344aa96.png?imageView2/0/interlace/1/format/png|imageslim";
      } else if (
        judgment(name, "tar") ||
        judgment(name, "7z") ||
        judgment(name, "bz2") ||
        judgment(name, "gz") ||
        judgment(name, "wim") ||
        judgment(name, "xz") ||
        judgment(name, "zip") ||
        judgment(name, "rar")
      ) {
        return "https://imfile.melinked.com/2023/12/c8f4d16f-b47b-498c-8e90-48ce191f2cbd.png?imageView2/0/interlace/1/format/png|imageslim";
      } else if (
        judgment(name, "png") ||
        judgment(name, "jpg") ||
        judgment(name, "gif") ||
        judgment(name, "jpeg")
      ) {
        return "https://imfile.melinked.com/2023/12/eaced268-7610-4415-88ce-0ebce3731160.png?imageView2/0/interlace/1/format/png|imageslim";
      } else if (
        judgment(name, "mp4") ||
        judgment(name, "avi") ||
        judgment(name, "mkv") ||
        judgment(name, "mov") ||
        judgment(name, "wmv") ||
        judgment(name, "MPEG") ||
        judgment(name, "3GP") ||
        judgment(name, "ASF") ||
        judgment(name, "FLV") ||
        judgment(name, "H.264") ||
        judgment(name, "TC")
      ) {
        return "https://imfile.melinked.com/2023/12/5dd7b52a-910e-412a-ad84-6a0f3d51ee5a.png?imageView2/0/interlace/1/format/png|imageslim";
      } else if (
        judgment(name, "wav") ||
        judgment(name, "ape") ||
        judgment(name, "aiff") ||
        judgment(name, "cd") ||
        judgment(name, "au") ||
        judgment(name, "mp3") ||
        judgment(name, "wma") ||
        judgment(name, "vqf") ||
        judgment(name, "midi") ||
        judgment(name, "ogg") ||
        judgment(name, "aac") ||
        judgment(name, "ram") ||
        judgment(name, "rm")
      ) {
        return `https://imfile.melinked.com/2023/12/6374037e-e0b4-42b8-98ee-c84d0c728f2f.png?imageView2/0/interlace/1/format/png|imageslim`;
      } else {
        return "https://imfile.melinked.com/2023/12/3d536f29-dc16-487c-92c1-b22deb179887.png?imageView2/0/interlace/1/format/png|imageslim";
      }
    },
    // app调js
    getAPPDate() {
      this.$bridge.registerHandler("dataToJs", (data) => {
        this.first = false;
        try {
          const jsonInfo = JSON.parse(data);
          const inputlang = jsonInfo.language || 1;
          const language = this.langs.filter(
            (lang) => lang.code == inputlang
          )[0];
          this.$i18n.locale = language.value;
          if (jsonInfo.calendar) {
            this.calendar = jsonInfo.calendar;
          }
        } catch (error) {}
        const handlerMsg = this.transformValue(data);
        this.msg = handlerMsg;
        this.$nextTick(() => {
          this.checkImagesLoaded();
          this.getInnerHeight();
        });
      });
    },
    // 将HTML渲染到iframe中
    loadHtmlStringIntoIframe(htmlString) {
      const iframe = this.$refs.editable_iframe;
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      iframeDocument.open();
      iframeDocument.write(htmlString);
      iframeDocument.close();
      // 获取 iframe 中的所有超链接，并兼容访问逻辑
      var links = iframeDocument.getElementsByTagName("a");
      for (var i = 0; i < links.length; i++) {
        links[i].setAttribute("target", "_blank");
      }
      iframeDocument.body.setAttribute(
        "style",
        "word-wrap: break-word;overflow-wrap: break-word;word-break: keep-all;"
      );
      this.adjustIframeHeight(iframe);
    },
    // 将iframe的高度适应内部
    adjustIframeHeight(iframe) {
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      this.iframe_height = iframeDocument.body.scrollHeight + 40;
      this.$bridge.callHandler(
        "heightToClient",
        this.iframe_height,
        (res) => {}
      );
    },
    checkImagesLoaded() {
      const box = document.querySelector(".textareaBox");
      const images = box.querySelectorAll("img");
      const count = images.length;
      var loaded = 0;
      Array.from(images).forEach((image) => {
        image.onload = () => {
          loaded++;
          if (loaded === count) {
            this.getInnerHeight();
          }
        };
      });
    },
    html_encode(str) {
      let s = "";
      if (str.length == 0) return "";
      s = str.replace(/&/g, "&gt;");
      s = s.replace(/</g, "&lt;");
      s = s.replace(/>/g, "&gt;");
      s = s.replace(/ /g, "&nbsp;");
      s = s.replace(/\'/g, "&#39;");
      s = s.replace(/\"/g, "&quot;");
      s = s.replace(/\n/g, "<br/>");
      return s;
    },
    transformValue(replyItemInfo) {
      try {
        const replayInfo = JSON.parse(replyItemInfo);
        // 记录发件人地址
        const fromRecord = replayInfo.originalFrom || replayInfo.from;
        try {
          replayInfo.from = this.html_encode(replayInfo.from);
        } catch (error) {}
        try {
          replayInfo.cc = this.html_encode(replayInfo.cc);
        } catch (error) {}
        try {
          replayInfo.to = this.html_encode(replayInfo.to);
        } catch (error) {}
        // 是否关闭滚动
        if (replayInfo.closeScroll) {
          this.isScroll = false;
        } else {
          this.isScroll = true;
        }
        // 是否关闭编辑
        if (replayInfo.closeEditor) {
          this.isContenteditable = false;
        } else {
          this.isContenteditable = true;
        }
        let sign = "";
        if (replayInfo.sign) {
          sign = Base64.decode(replayInfo.sign);
          let newNode = document.createElement("div");
          newNode.setAttribute("data-tag", "melinkedSignature");
          newNode.setAttribute("contenteditable", "false");
          newNode.setAttribute(
            "style",
            "width:100%; min-height:100px; overflow:hidden;line-height: normal;position: relative;box-sizing: border-box;padding: 18px 0;border-top: 1px solid #f0f0f0;"
          );
          newNode.id = "signatureBlock";
          newNode.innerHTML = sign;
          sign = newNode.outerHTML;
        }
        // 是否显示信息
        if (!replayInfo.showInfo) {
          let content = this.extractBodyContent(
            Base64.decode(replayInfo.content)
          );
          content = this.handlerCidImageHtmlString(content, {
            messageId: replayInfo.messageId,
            from: fromRecord,
          });
          return content + sign;
        }
        const htmlStr = `
        <div data-tag="melinkedOriginal" contenteditable="false" style="width: 100%;height: 28px;text-align: center;position: relative;display: flex;align-items: center;justify-content: center;margin: 16px 0">
          <div style="position: absolute;top: 50%;left:0;right: 0;border-top: 1px dashed #D9D9D9;border-bottom: 1px dashed #D9D9D9;content:'';transform: translate3d(0, -50%, 0);"></div>
          <div style="position: relative;z-index: 1;background-color: white;color:#858585;font-size:14px">${this.$t(
            "originalMail"
          )}</div>
        </div>
        `;
        const subjectHtml = `<div style="width:100%;min-height:25px;color:#292D32;font-size:18px;font-weight:600;line-height:25px;" title="${replayInfo.subject}">${replayInfo.subject}</div>`;
        const fromHtml = `
        <div style="width:100%;min-height:22px;;line-height:22px;color:#858585;font-size: 14px;margin-top:6px;">
          ${this.$t(
            "emailFrom"
          )}: <span style="color:#F39945;font-size:16px"></span> ${
          replayInfo.from
        }
        </div>
        `;
        const toHtml = `
        <div style="width:100%;min-height:22px;;line-height:22px;color:#858585;font-size: 14px;margin-top:6px;word-break:break-all">
          ${this.$t("emailRecipient")}:${replayInfo.to}
        </div>
        `;
        const ccHtml = replayInfo.cc
          ? `
        <div style="width:100%;min-height:22px;;line-height:22px;color:#858585;font-size: 14px;margin-top:6px;;word-break:break-all">
          ${this.$t("CcTo")}: ${replayInfo.cc}
        </div>`
          : "";
        const dateHtml = `
        <div style="width:100%;min-height:22px;;line-height:22px;color:#858585;font-size: 14px;margin-top:6px;">
          ${this.$t("time")}: ${replayInfo.date}
        </div>
        `;
        const infoBlock = `<div style="width: 100%;box-sizing: border-box;min-height: 0px;background: #F5F5F5;border-radius: 4px;padding: 12px;margin-bottom:16px">${subjectHtml}${fromHtml}${toHtml}${ccHtml}${dateHtml}</div>`;
        let handleArr = `${htmlStr}${infoBlock}`.split("\n");
        handleArr = handleArr.map((e) =>
          e.replace(/^[\s]+/, "").replace(/[\s]+$/, "")
        );
        let content = replayInfo.content
          ? Base64.decode(replayInfo.content)
          : "";
        content = this.extractBodyContent(content);
        content = this.handlerCidImageHtmlString(content, {
          messageId: replayInfo.messageId,
          from: fromRecord,
        });
        return (
          `<br/><br/><br/><br/>` +
          sign +
          handleArr.join("") +
          this.extractBodyContent(content)
        );
      } catch (error) {}
    },
    getData() {
      this.$bridge.registerHandler("getDataFromJs", (data) => {
        if (!this.first) {
          this.sendMsg();
        }
      });
    },
    getInnerHeight() {
      setTimeout(async () => {
        if (this.$refs.textareaBox) {
          const actualHeight = this.getActualheight();
          this.$bridge.callHandler("heightToClient", actualHeight, (res) => {});
        }
      }, 0);
    },
    async getCurrentHeight() {
      setTimeout(async () => {
        if (this.$refs.textareaBox) {
          const actualHeight = this.getActualheight();
          this.$bridge.callHandler(
            "currentHeightToClient",
            actualHeight,
            (res) => {}
          );
        }
      }, 0);
    },
    getActualheight() {
      const parentElement = this.$refs.textareaBox;
      let tempElement = document.createElement("div");
      tempElement.style.visibility = "hidden";
      tempElement.style.position = "absolute";
      tempElement.style.top = "0px";
      tempElement.style.left = "100%";
      tempElement.style.width = parentElement.offsetWidth + "px";
      tempElement.innerHTML = parentElement.innerHTML;
      parentElement.appendChild(tempElement);
      let actualHeight = tempElement.offsetHeight;
      if (actualHeight > 100) {
        actualHeight = parentElement.scrollHeight;
      }
      tempElement.remove();
      return actualHeight;
    },
    sendMsg() {
      let attachmentHtml = this.$refs.textareaBox.innerHTML;
      attachmentHtml = attachmentHtml.replace(
        /background:rgba[(]0,0,0,0.5[)]/g,
        "display:none"
      );
      this.$bridge.callHandler("dataToAndroid", attachmentHtml, (res) => {});
    },
  },
};
</script>
<style lang="stylus">
.testAreaBox
  width: 100%;
  height: 100%;
  img
    width: 100%;
.textareaBox
  ol
    list-style-type: decimal;
    padding: inherit;
    margin: inherit;
  ul
    list-style-type: disc;
    padding: inherit;
    margin: inherit;
</style>
<style lang="stylus" scoped>
// .editableBox
//   width: 100%;
//   height: 100%;
//   #editable_iframe
//     width: 100%;
//     overflow: hidden;
  .scroll
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;
  .auto
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  .textareaBox[contenteditable]
    &:focus
      outline none
  .textareaBox
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    position: relative;
    white-space:pre-wrap;
    background: #FFFFFF;
    .showHtml
      width: 100%;
      height: 100%;
      display: none;
      white-space:pre-wrap;
    .ProseMirror-focused
      border: none;
      outline: none;
    .ProseMirror
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      border: none;
      resize: none;
      white-space:pre-wrap;
</style>
